.globe {
    width: 40vw;
    height: 40vw;
    position: absolute;
    left:-9vw;
    top:-4vw;
    margin-bottom:-6vw;
    z-index: 0;
    pointer-events: none !important;
    opacity:0;
    // background-color: blue;

    div {
        pointer-events: none;
    }

    canvas {
        width:100%;
        height: 100%;
        opacity:.4;
        pointer-events: none;
    }

    @media only screen and (max-width: 1023px) {
        display: none;
    }
}
