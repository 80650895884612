.content {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: fixed;
    left:0;
    top:0;
    background: radial-gradient(#031c28 ,  transparent 70%);
    background-color: #020507;
   

    .background-glitch {
        width:100%;
        height: 100vh;
        object-fit: cover;
        z-index: 100;
        pointer-events: none;
        user-select: none;
        position: fixed;
        top:0;
        left:0;
        opacity:.05;
        filter:blur(4px);
    }
    .background-glitch2 {
        width:100%;
        height: 100vh;
        object-fit: cover;
        z-index: 100;
        pointer-events: none;
        user-select: none;
        position: fixed;
        top:0;
        left:0;
        opacity:.02;
        filter:blur(2px);
    }

   


    .wheel-detect { 
        height: 100%;
        width: 80%;
        margin-left:15vw;
        position: absolute;
        pointer-events: auto;
        z-index: 100;
    }
}
