@font-face {
  font-family: "OCRAStd";
  src: url("./fonts/OCRAStd.woff2") format("woff2"),
    url("./fonts/OCRAStd.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "OCRAStd";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: radial-gradient(#031c28 ,  transparent 100%);
  // background-color: #00081D;
  
}

p,h1,h2,h3,h4,h5,h6,a,button {
  font-family: "OCRAStd";
}
