.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height: 2vw;
  position: fixed;
  top:0;
  left:0;
  background-color: rgb(12, 19, 20);
  opacity: 0;

  .left {
    display: flex;
    flex-direction: row;
  }

  .header-title {
    color:#fff;
    margin-left:2vw;
    font-size:.84vw;
    opacity: .8;
  }
  .header-about {
    color:#fff;
    margin-left:2vw;
    cursor: pointer;
    display: none;
  }

  .sound-button {
    margin-right:1vw;
    color:#fff;
    margin-left:2vw;
    cursor: pointer;
    background:transparent;
    border:none;
    cursor: pointer;
    font-size:.84vw;
    font-family: "OCRAStd";
    opacity:.8;

    span {
      font-family: "OCRAStd";
      font-size:.84vw;
      &.active {
        font-weight: 700;
        color: #5DE7FF;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    height: 4vw;

    .header-title {
      margin-left:4vw;
      font-size:2.4vw;
    }
    .header-about {
      margin-left:4vw;
      cursor: pointer;
      display: flex;
      font-size:2.4vw;
      opacity: .8;
    }
  
    .sound-button {
      margin-right:2vw;
      margin-left:4vw;
      font-size:2.4vw;
  
      span {
        font-family: "OCRAStd";
        font-size:2.4vw;
      }
    }
  }

 
 

}