.terminal {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow:hidden;
    z-index: 100;
    opacity: .6;
    user-select: none;
    pointer-events: none;


    .col {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;
        font-size:.76vw;
       
        color: #5DE7FF;
        text-shadow: #5DE7FFcc 0px 0px 1.4vw;

        width:26vw;
        text-align: left;

        margin-left: 2vw;

        overflow-y:scroll;

        height: 48%;
        pointer-events: none;
        // background-color: red;

        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        // overflow-y: scroll;
          
        ::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }

        .cursor {
            
            color: #5DE7FF;
            text-shadow: #5DE7FFcc 0px 0px 1.4vw;
            height: 2.0vw;
            width: 1.0vw;
            opacity: 0;
            position: relative;
        
        }

    }

    .col-text7 {
       overflow-y:scroll;
       -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        ::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
       height: 40%;
       position: relative;

    //    background-color: purple;
       display: flex;
       flex-direction: column;
       text-align: left;
       margin-left:2vw;
       font-size:.76vw;
       
       color: #5DE7FF;
       text-shadow: #5DE7FFcc 0px 0px 1.4vw;
    }

    .col-about {
        color:#f8c98ecc;
        font-size:1.0vw;
        background:none;
        border:none;
        margin-top:0vw;
        margin-bottom:1vw;
        opacity: 1;
        position: relative;
        z-index: 1000;
        cursor: pointer;
        pointer-events: all;
        opacity: 0;
    }

    .col-about-detail {
        color:#f8c98ecc;
        a{
            color:#f8c98ecc;
        }
    }

    .col-about-detail-email {
        cursor: pointer;
        color:#f8c98ecc;
        margin-bottom:1vw;
        margin-top:1vw;
        pointer-events: all;
    }

    @media only screen and (max-width: 1023px) {
        .col {
            font-size:2.4vw;
            width:90%;
            text-align: left;
            margin-left: 4vw;
            overflow-y:scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            ::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
            height: 100%;
            pointer-events: none;
            margin-top:8vw;
        }

        .col-text7 {
            margin-top:0;
            overflow-y:scroll;
            height: 100%;
            margin-left:4vw;
            font-size:2.4vw;

            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            ::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
        }

        .col-about {
            font-size:3vw;
            margin-bottom:4vw;
        }
    
        .col-about-detail-email {  
            margin-bottom:4vw;
            margin-top:2vw;
        }
    }
}
