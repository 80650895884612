.about-button {
  color:#ff8e24;
  font-size:1.4vw;
  background:none;
  border:none;
  margin-top:-1.5vw;
  margin-bottom:1vw;
  opacity: 1;
  position: absolute;
  top:14vw;
  left:2vw;
  height:5vw;
  width:12vw;
  z-index: 1000;
  // cursor: pointer;
  // pointer-events: all;
  opacity: .3;
  background:transparent;
}
