.project-detail {
    width: 35%;
    height: 100%;
    position: absolute;
    left:unset;
    top:1vw;
    z-index: 1000;
    pointer-events:all;
    opacity:1;
    display: flex;
    flex-direction: column;
    color: #5DE7FF;
    align-items: center;
    justify-content: center;
    right:-100%;
    
    .box {
        position: absolute;
        width:100%;
        height: 100%;
        
        left:0;
        top:0;
        z-index:200;
        pointer-events: none;
        user-select: none;

        //filter: drop-shadow(rgba(255,255,255,1) 0px  0px  0px) brightness(1.0);
        // filter: brightness(10.0);
    
    }

    .box-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-self: flex-start;

        overflow-y:auto;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        ::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
        height: 91%;
        background: rgb(13, 18, 22);
        border-radius:1vw;
       // backdrop-filter: blur(10px);
        width:85.8%;
        z-index:10;
       

        .sub-title {
            position: relative;
            padding:0;
            margin:0;
            margin-top:2vw;
            margin-left:1vw;
            font-size: .8vw;
            opacity: .8;
            color: #5DE7FF;
        }
    
        .title {
            position: relative;
            padding:0;
            margin:0;
            margin-top:.3vw;
            margin-left:1vw;
            font-size: 2vw;
            font-weight: 900;
            text-transform: uppercase;
            line-height: 100%;
            width:90%;
            text-align: left;
            opacity: .8;
    
            color: #5DE7FF;
        }
    
        .tags {
            position: relative;
            padding:0;
            margin:0;
            margin-top:1vw;
            margin-left:1vw;
            font-size: .7vw;
            opacity: .8;
            color: #5DE7FF;
        }
    
        .feature-image {
            width:100%;
            // margin-left:2.4vw;
            margin-top:1.2vw;
            // margin-bottom:1vw;
            pointer-events: none;
            user-select: none;
        }

        .feature-video {
            // width:100% !important;
            // height: auto !important;
           
            pointer-events: all;
            // pointer-events: none;
            // user-select: none;
            position: absolute;
            top: 0;
            left: 0;
           

        }

       
        .player-wrapper {
            width: 100%;
            position: relative;
            padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
          }
          
     
    
        .content-blocks {
            width:100%;
            text-align: left;
            padding-bottom:5vw;
            
            z-index: 10;
            p {
                margin:0;
                padding: 0;
                margin-top:1vw;
                width:92%;
                margin-left:1vw;
                opacity: .8;
                // word-break: break-all;
                color: #ffffff;
                font-size:.84vw;
            }
            a {
                color: #5DE7FF;
                opacity: .8;
                word-break: break-all;
                font-size:.84vw;
            }
    
            .image {
                width:100%;
                // margin-left:3.6vw;
                margin-top:1vw;
              
            }
        }
    }
    .close-button { 
        display: none;
    }
    
    @media only screen and (max-width: 1023px) {
        width:100%;
        height:100%;
        background:rgba(0,0,0,.9);

        .close-button { 
            display: flex;
            position: absolute;
            z-index: 1000;
            top:10vw;
            right:8vw;
            background: none;
            color:#fff;
            font-size: 6vw;
            border:none;
            cursor: pointer;
        }

        .box-content {
            height: 91%;
            border-radius:4vw;
            width:85.8%;
    
            .sub-title {
                margin-top:6vw;
                margin-left:4vw;
                font-size: 3vw;
            }
        
            .title {
                margin-top:2vw;
                margin-left:4vw;
                font-size: 5.4vw;
                width:90%;
            }
        
            .tags {
                margin-top:3vw;
                margin-left:4vw;
                font-size: 3vw;
            }
        
            .feature-image {
                margin-top:4vw;
            }
    
            .feature-video {
                margin-top:4vw;
                margin-bottom:6vw;
            }
         
            .content-blocks {
                margin-top:3vw;
                padding-bottom:6vw;
                p {
                    margin-top:3vw;
                    margin-left:4vw;
                    font-size:3vw;
                    
                }
                a {
                    font-size:3vw;
                    white-space: nowrap;
                }
        
                .image {
                    margin-top:3vw;
                }
            }
        }

    }

}
