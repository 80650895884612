.network {
    width: 20vw;
    height: 4vw;
    position: relative;
    left:0;
    
    margin-bottom:2vw;
    z-index: 100;
    pointer-events: all;
    opacity:0;


    filter: drop-shadow(#5DE7FFcc 0px 0px 1.4vw);

    svg {
        margin:0;
        padding: 0;
        margin-top:-7.5vw;
        position: relative;
        // width: 100%;
        // height: 100%;
        opacity:.6;
        
    }
}