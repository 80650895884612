.project-windows {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    z-index: 100;
    pointer-events: none;
    opacity:1;

    canvas {
        width:100%;
        height: 100%;
        
    }

    
}

.project-window {
    width:28vw;
    height: 20vw;
    display: flex;
   
    //opacity: 0;
    // background: black;

    .project-window-inner {
        width:100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //backdrop-filter: blur(4px);
        flex-direction: column;
        
        opacity: .9;
        transition: all .5 ease-in-out ;
        
        
        &.active {
            filter: drop-shadow(rgba(255,255,255,1) 0px  0px  5px);
           
        }

        .box {
            width:100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
            z-index: 100;
            left:0;
            top:0;
            transition: filter .5 ease-out;
            
            &.active {
                filter: brightness(10.0);
                //
                //backdrop-filter: blur(1px) saturate(.1);
                //box-shadow: rgba(72, 135, 202, 0.8) 0 0 10px 5px;
            }
            cursor: pointer;
            z-index: 1000;
            pointer-events: all;
            -webkit-user-drag: none;
            user-select: none !important;
        }

        .image {
            width:94%;
            align-self: center;
            height: 72%;
            object-fit: cover;
            // margin-top:10%;
            border-bottom:.5px solid #aaccff;
            filter: saturate(70%);
            position: absolute;
            user-select: none;
        }
        
        .titles-wrapper {
            position: absolute;
            bottom:13%;
            background:rgba(0,0,0,.8);
            width:94%;
            // align-self: center;
            align-items: flex-start;
            justify-content: flex-start;
            height: 19%;
            display: flex;
            flex-direction: column;
        }

        .sub-title {
            color: #5DE7FF;
            font-size: .6vw;
            margin:0;
            padding: 0;
            margin-top:.4vw;
            margin-left:1vw;
            opacity:.8;
        }

       .title {
            
            color: #5DE7FF;
            opacity:.8;
            font-size: 1.3vw;
            margin:0;
            padding: 0;
            margin-top:0vw;
            margin-left:1vw;
            text-transform: uppercase;
   
           
        }

        .tags {
            color: #5DE7FF;
            font-size: .6vw;
            margin:0;
            padding: 0;
            margin-top:.2vw;
            margin-left:1vw;
            opacity:.8;
            display: none;
        }

    }

    .intro {
        .box {
            display: none; 
        }
        .image { 
            display: none; 
        }
        .sub-title {
            display: none;
        }
        .tags{
            display: none;
        }
        .title {
            text-align: center;
            user-select: none;

            color: #ffffff;
            opacity:.8;
            font-size: 1.4vw;
            margin:0;
            padding: 0;
            margin-top:0vw;
            margin-left:1vw;
            text-transform: lowercase;
        }
        .titles-wrapper {
            position: relative;
            bottom:unset;
            background:transparent;
            width:94%;
            // align-self: center;
            align-items: center;
            justify-content: center;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
  

}