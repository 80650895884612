.mobile-project-windows {
    width: 100%;
    height: 100%;
    position: relative;
    left:0;
    top:0;
    z-index: 100;
    //pointer-events: none;
    display:none;
    overflow-y:scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
    opacity: 0;
    margin-top:0;
    padding-top:10vw;
    
    // padding-bottom:30vw;
    // margin-bottom:30vw;
   
    @media only screen and (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        gap:4vw;
        z-index: 1000;
        top:100%;
    }

    .mobile-project-window {
        width:100%;
        height: 50vw;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: .9;
        transition: all .5 ease-in-out ;
        position: relative;

       &.last {
         margin-bottom:25vh;
       }
       
        // &.intro {
        //     display: none;
        // }

        // background:red;
            
        &.active {
            filter: drop-shadow(rgba(255,255,255,1) 0px  0px  5px);
        }
    
        .box {
            //display: none;
            width:100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
            z-index: 100;
            left:0;
            top:0;
            transition: filter .5 ease-out;
            
            &.active {
                filter: brightness(10.0);
                //
                //backdrop-filter: blur(1px) saturate(.1);
                //box-shadow: rgba(72, 135, 202, 0.8) 0 0 10px 5px;
            }
            cursor: pointer;
            z-index: 1000;
            pointer-events: all;
            -webkit-user-drag: none;
            user-select: none !important;
        }
    
        .image {
            width:86vw;
            // align-self: center;
            // justify-content: center;
            height:45vw;
            // margin:0;
            // left:0;
            // top:0;
            
            object-fit: cover;
            margin-top:0vw;
            border-bottom:.5px solid #aaccff99;
            filter: saturate(70%);
            //position: absolute;
            user-select: none;
        }
        
        .titles-wrapper {
           
            position: absolute;
            bottom:2vw;
            background:rgba(0,0,0,.8);
            width:86vw;
            // align-self: center;
            align-items: flex-start;
            justify-content: flex-start;
            height:14vw;
            display: flex;
            flex-direction: column;
            // display: none;
        }

        .sub-title {
            color: #5DE7FF;
            font-size: 2.4vw;
            margin:0;
            padding: 0;
            margin-top:3vw;
            margin-left:3vw;
            opacity:.8;
        }

        .title {
            
            color: #5DE7FF;
            opacity:.8;
            font-size: 4.2vw;
            margin:0;
            padding: 0;
            margin-top:1vw;
            margin-left:3vw;
            text-transform: uppercase;
    
            
        }

        .tags {
            color: #5DE7FF;
            font-size: .6vw;
            margin:0;
            padding: 0;
            margin-top:.2vw;
            margin-left:1vw;
            opacity:.8;
            display: none;
        }

        
    
        &.intro {
            //display: none;
            height: 24vw;
            .box {
                display: none; 
            }
            .image { 
                display: none; 
            }
            .sub-title {
                display: none;
            }
            .tags{
                display: none;
            }
            .title {
                text-align: center;
                user-select: none;
    
                color: #ffffff;
                opacity:.8;
                font-size: 4vw;
                margin:0;
                padding: 0;
                margin-top:0vw;
                margin-left:1vw;
                text-transform: lowercase;
            }
            .titles-wrapper {
                position: relative;
                bottom:unset;
                background:transparent;
                width:94%;
                // align-self: center;
                align-items: center;
                justify-content: center;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
      
    
    }
    
}

