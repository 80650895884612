.login {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: .7;



    .prompt-title {
        position: relative;
        z-index: 5;
        font-size:2.0vw;
        /* original namethemachne color */
        //color: #B9E8EE;
        color: #5DE7FF;
        text-align: center;
        text-shadow: #5DE7FFcc 0px 0px 1.4vw;
    }

    .start-button {
        color: #5DE7FF;
        text-align: center;
        text-shadow: #5DE7FFcc 0px 0px 1.4vw;
        background: none;
        font-size: 1vw;
        padding:.7vw;
        padding-left:2vw;
        padding-right:2vw;
        border:2px solid #5DE7FFcc;
        font-family: "OCRAStd";
        font-weight: 700;
        filter:drop-shadow(0px 0px 1.4vw);
        margin-top:6vw;
        cursor: pointer;
        scale:1;
        transition: scale .3s ease-out;

        &:active{
            scale:.95;
        }
    }

    .prompt {
        position: relative;
        z-index: 5;
        font-size:1.6vw;
        /* original namethemachne color */
        //color: #B9E8EE;
        color: #5DE7FF;
        text-align: center;
        text-shadow: #5DE7FFcc 0px 0px 1.8vw;
        display: flex;
        flex-direction: row;

        .prompt-text {
            text-transform: lowercase;
            font-weight: 100;
        }

        .prompt-cursor {
            color: #5DE7FF;
            text-align: center;
            text-shadow: #5DE7FFcc 0px 0px 1.8vw;
            background: #5DE7FF;
            height: 1.8vw;
            margin-left:.2vw;
            width: 1.0vw;
            opacity: 0;
        }
    }

    @media only screen and (max-width: 1023px) {
        .prompt-title {
            font-size:6.0vw;
        }

        .start-button {
            font-size: 3.2vw;
            padding:2vw;
            padding-left:4vw;
            padding-right:4vw;
            margin-top:10vw;

            filter:none;
 
        }

      }


}
