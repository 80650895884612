.ai {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    z-index: 100;
    pointer-events:auto;
    opacity:1;

    canvas {
        width:100%;
        height: 100%;
        
    }

    .background-glitch {
        width:100%;
        height: 100vh;
        object-fit: cover;
        z-index: 100;
        pointer-events: none;
        user-select: none;
        position: fixed;
        top:0;
        left:0;
        opacity:.2;
        filter:blur(5px);
    }

    @media only screen and (max-width: 1023px) {
        display: none;
    }
}
