.about{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height: 100vh;
  position: absolute;
  top:0;
  left:0;
  z-index:0;
  background-color: rgb(9, 15, 16);
  opacity: 0;
  display: none;

  @media screen and (orientation:portrait) and (max-width:1023px) {
    // margin-bottom: calc(8.4vw + 164px);
    display: flex;
    position: relative;
    flex-direction: column;

    .about-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      flex-direction: column;

      .close-button { 
        display: flex;
        position: absolute;
        z-index: 1000;
        top:6vw;
        right:6vw;
        background: none;
        color:#fff;
        font-size: 6vw;
        border:none;
        cursor: pointer;
      
      }

      .col-about-detail {
        display: flex;
        font-size:4vw;
        margin-bottom:4vw;
        color:#f8c98ecc;
        width:84%;
        position: relative;
        a{
            color:#f8c98ecc;
        }
        text-align: left;
      }
  
      .col-about-detail-email {
          cursor: pointer;
          color:#f8c98ecc;
          pointer-events: all;
          margin-bottom:4vw;
          margin-top:2vw;
          text-align: left;
          width:84%;
      }
    }



   


  }

 
 

}