.email-button {
  color:#ff8e24;
  font-size:1.4vw;
  background:none;
  border:none;
  margin-top:-.5vw;
  margin-bottom:1vw;
  opacity: 1;
  position: absolute;
  top:-20vw;
  left:2vw;
  height:3vw;
  background:transparent;
  width:16vw;
  z-index: 2000;
  cursor: pointer;
  pointer-events: all;
  opacity: .3;
  background:transparent;
}
