.ai-shell {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    z-index: 100;
    pointer-events: all;
    opacity:1;

    canvas {
        width:100%;
        height: 100%;
        
    }
}
